import React, { useContext } from "react"
import { Link } from "gatsby"
import styles from "./../styles/demo.module.scss"
import withAppContext from "../contexts/app.context"
import Layout from "../components/layout/layout"
import { ShowAnnouncementContext } from "../contexts/show-announcement.context"

const ThanksConsultPage = () => {
  const { showAnnouncement } = useContext(ShowAnnouncementContext)

  return (
    <Layout>
      <div className={`container ${styles.pageContainer}`}>
        <div className="row">
          <div
            ves-e2e-test="demo/consult-form-success-section"
            className={
              `col-12 align-items-center ${styles.success} ${styles.successConsult}` +
              (showAnnouncement ? styles.successIndent : "")
            }>
            <p className={styles.success__title}>Thanks for your interest</p>
            <p className={styles.success__description}>
              Our sales team will follow up on your email shortly.
            </p>
            <p
              className={`${styles.success__text} ${styles.success__textGray}`}>
              In the meantime, you can learn more through our{" "}
              <Link
                aria-label="Resources"
                to={"/resources/center"}
                className={styles.success__link}>
                resources{" "}
              </Link>
              and{" "}
              <Link
                aria-label="Blog"
                to={"/resources/blog"}
                className={styles.success__link}>
                blog
              </Link>
              .
            </p>
            <p className={styles.success__text}>
              We look forward to connecting with you!
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default withAppContext(ThanksConsultPage)
